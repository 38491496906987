import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Chip,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HelpToolTip from "../components/common/HelpToolTip";
import { FetchLocales } from "../helpers/FetchData";
import CenteredSpinner from "../components/common/CenteredSpinner";
import { useConfig } from "../context";

const AudienceModal: React.FC<any> = ({
  addAudience,
  attribute,
  dialogOpen,
  dialogClose,
  editDisabled,
  attributes,
  isCustom,
  toggleStore,
  checkAllowedLocales,
  brandId,
}) => {
  const [data, setData] = useState({
    name: "",
    attribute: "",
    values: [""],
    matchType: "eq",
    description: "",
  });
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [customAttribute, setCustomAttribute] = useState("");
  const [attributeIsCustom, setAttributeIsCustom] = useState(false);
  const [isOr, setIsOr] = useState(false);
  const [isStore, setIsStore] = useState(false);
  const [localesList, setLocalesList] = useState([]);
  const [touchpointsList, setTouchpointsList] = useState([]);
  const { config } = useConfig();

  useEffect(() => {
    if (isCustom) {
      setAttributeIsCustom(true);
      setCustomAttribute(attribute.attribute);
    } else {
      setAttributeIsCustom(false);
    }
    attribute.matchType = attribute.matchType || "eq";
    setData(attribute);
  }, [attribute, isCustom]);

  useEffect(() => {
    if (dialogOpen) {
      FetchLocales((output: any) => {
        if (output?.status !== 200) {
          console.log(output.message);
        } else {
          setLocalesList(output.response);
        }
      });
    }
  }, [dialogOpen, config]);

  useEffect(() => {
    setTouchpointsList(config.touchpoints);
  }, [config]);

  const checkSubmit = useCallback(() => {
    const nameOk = data.name.length > 0;
    const valuesOk = !data.values.some((i) => i.length < 1);
    const attributeOk = attributeIsCustom
      ? customAttribute.length > 0
      : data.attribute.length > 0;
    const description = isStore ? data.description.length > 0 : true;
    setEnableSubmit(nameOk && valuesOk && attributeOk && description);
  }, [data, attributeIsCustom, customAttribute, isStore]);

  useEffect(() => {
    checkSubmit();
  }, [checkSubmit]);

  useEffect(() => {
    setIsOr(/^eq|^sub/.test(data.matchType));
  }, [data]);

  const addField = () => {
    setData((prevState: any) => {
      const values = prevState.values;
      values.push("");
      const data = { ...prevState, values: values };
      return data;
    });
  };

  const removeField = (e: any) => {
    if (data.values.length > 1) {
      setData((prevState: any) => {
        const values = prevState.values;
        values.pop();
        const data = { ...prevState, values: values };
        return data;
      });
    }
  };

  const addAudienceWrapper = (e: any) => {
    setIsStore(false);
    addAudience(e);
  };

  const toggleStoreWrapper = (e: any) => {
    toggleStore(e);
    setIsStore(e.target.checked);
  };

  const changeAttribute = (e: any) => {
    setCustomAttribute("");
    if (e.target.value === "custom") {
      setAttributeIsCustom(true);
    } else {
      setAttributeIsCustom(false);
    }
    setData((prevState: any) => {
      return {
        ...prevState,
        attribute: e.target.value,
        values: [""],
        matchType: e.target.value === "segments" ? "sub" : "eq",
      };
    });
  };

  const nameChange = (e: any) => {
    setData((prevState: any) => {
      return { ...prevState, name: e.target.value };
    });
  };

  const descChange = (e: any) => {
    setData((prevState: any) => {
      return { ...prevState, description: e.target.value };
    });
  };

  const changeCustomAttributeChange = (e: any) => {
    setCustomAttribute(e.target.value);
    setData((prevState: any) => {
      return { ...prevState, attribute: e.target.value };
    });
  };

  const changeMatchType = (e: any) => {
    setData((prevState: any) => {
      return { ...prevState, matchType: e.target.value };
    });
  };

  const localeValueChange = (index: number) => (e: any) => {
    setData((prevState: any) => {
      const values = prevState.values;
      values[index] = e.target.value;
      const data = { ...prevState, values: values };
      return data;
    });
  };
  const touchpointValueChange = (index: number) => (e: any) => {
    setData((prevState: any) => {
      const values = prevState.values;
      values[index] = e.target.value;
      const data = { ...prevState, values: values };
      return data;
    });
  };

  const valueChange = (e: any) => {
    const index = e.target.dataset.index;
    setData((prevState: any) => {
      const values = prevState.values;
      values[index] = e.target.value;
      const data = { ...prevState, values: values };
      return data;
    });
  };

  const specialValueItem = (attribute: string, item: any, key: number) => {
    return attribute === "locale"
      ? localeValueItem(item, key)
      : touchpointValueItem(item, key);
  };

  const valueItems = () => {
    return data.values.map((item: any, key: any) => (
      <Grid
        xs="auto"
        item
        alignItems="center"
        container
        key={key}
        sx={{
          pb: 2,
          "&:last-child svg": { display: "inline-block!important" },
          "&:last-child .MuiChip-root": { display: "none!important" },
        }}
      >
        <Grid item>
          {data.attribute === "locale" || data.attribute === "touchpoint" ? (
            specialValueItem(data.attribute, item, key)
          ) : (
            <TextField
              margin="none"
              value={item || ""}
              type="text"
              fullWidth
              size="small"
              inputProps={{ "data-index": key }}
              onChange={valueChange}
              sx={{ width: 260 }}
            />
          )}
        </Grid>
        <Grid
          item
          sx={{ px: 2, alignItems: "center", display: "flex" }}
          alignItems="center"
        >
          <AddCircleOutlineIcon
            onClick={addField}
            sx={{ display: "none", cursor: "pointer" }}
          ></AddCircleOutlineIcon>
          {key > 0 ? (
            <RemoveCircleOutlineIcon
              data-index={key}
              onClick={removeField}
              sx={{ display: "none", cursor: "pointer" }}
            ></RemoveCircleOutlineIcon>
          ) : (
            <></>
          )}
          <Chip
            size="small"
            label={isOr ? "OR" : "NOR"}
            variant="filled"
            sx={{ "& span": { px: "4px" } }}
          />
        </Grid>
      </Grid>
    ));
  };

  const localeValueItem = (item: any, key: number) => {
    if (localesList.length < 1) {
      return <CenteredSpinner size={20} />;
    } else {
      return (
        <FormControl sx={{ width: 160 }}>
          <Select
            size="small"
            onChange={localeValueChange(key)}
            value={item || ""}
            displayEmpty
            autoWidth
          >
            <MenuItem key="" value="">
              Select locale
            </MenuItem>
            {localesList.map((locale: any) => {
              return checkAllowedLocales(locale.name) &&
                locale.brand_id === brandId ? (
                <MenuItem key={locale.name} value={locale.name}>
                  {locale.name}
                </MenuItem>
              ) : (
                false
              );
            })}
          </Select>
        </FormControl>
      );
    }
  };

  const touchpointValueItem = (item: any, key: number) => {
    return (
      <FormControl sx={{ width: 180 }}>
        <Select
          size="small"
          onChange={touchpointValueChange(key)}
          value={item || ""}
          displayEmpty
          autoWidth
        >
          <MenuItem key="" value="">
            Select touchpoint
          </MenuItem>
          {touchpointsList.map((i: any) => (
            <MenuItem key={i.name} value={i.name}>
              {i.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Dialog open={dialogOpen} onClose={dialogClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {attribute.index > -1 ? "Update audience" : "Create audience"}{" "}
      </DialogTitle>
      <DialogContent>
        <Grid
          spacing={2}
          container
          sx={{ p: 2 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Name</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                value={data.name}
                type="text"
                fullWidth
                size="small"
                onChange={nameChange}
              />
            </Grid>
          </Grid>
          {isStore ? (
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Description</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  value={data.description || ""}
                  type="text"
                  fullWidth
                  size="small"
                  onChange={descChange}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Attribute</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Match type</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "100%", maxWidth: 200 }}>
                <Select
                  size="small"
                  onChange={changeAttribute}
                  value={attributeIsCustom ? "custom" : data.attribute}
                  displayEmpty
                >
                  <MenuItem key={0} value="">
                    Select attribute
                  </MenuItem>
                  {attributes.map((item: any) => {
                    return (
                      <MenuItem
                        key={item.id}
                        value={item.name}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                  <MenuItem key={999999} value="custom">
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  size="small"
                  value={data.matchType}
                  displayEmpty
                  disabled={/^locale$|^touchpoint$/.test(data.attribute)}
                  onChange={changeMatchType}
                >
                  <MenuItem
                    key={0}
                    value="eq"
                    disabled={/^segments$/.test(data.attribute)}
                  >
                    Is
                  </MenuItem>
                  <MenuItem
                    key={1}
                    value="ne"
                    disabled={/^segments$/.test(data.attribute)}
                  >
                    Is NOT
                  </MenuItem>
                  <MenuItem key={2} value="sub">
                    Contains
                  </MenuItem>
                  <MenuItem key={3} value="nosub">
                    Contains NOT
                  </MenuItem>
                  <MenuItem
                    key={4}
                    value="gt"
                    disabled={/^segments$/.test(data.attribute)}
                  >
                    Greater than
                  </MenuItem>
                  <MenuItem
                    key={5}
                    value="lt"
                    disabled={/^segments$/.test(data.attribute)}
                  >
                    Less than
                  </MenuItem>
                  <MenuItem
                    key={6}
                    value="gte"
                    disabled={/^segments$/.test(data.attribute)}
                  >
                    Greater than or equal
                  </MenuItem>
                  <MenuItem
                    key={7}
                    value="lte"
                    disabled={/^segments$/.test(data.attribute)}
                  >
                    Less than or equal
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {attributeIsCustom ? (
            <Grid item xs={12} container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  value={customAttribute}
                  label="Custom attibute"
                  type="text"
                  size="small"
                  onChange={changeCustomAttributeChange}
                  sx={{ width: 260 }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Define your custom attribute
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Value(s)</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              container
              sx={{ pl: 1, pr: 2 }}
              alignItems="center"
              justifyContent="flex-start"
            >
              {valueItems()}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          spacing="1"
          container
          sx={{ p: 2 }}
          alignItems="flex-start"
          justifyContent="flex-start"
        ></Grid>
        <Grid
          spacing="1"
          container
          sx={{ p: 2 }}
          alignItems="flex-start"
          justifyContent="flex-start"
        ></Grid>

        {attribute.index === -1 && config.user.level > 99 ? (
          <Stack direction="row" justifyContent="end">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={toggleStoreWrapper}
                  defaultChecked={false}
                />
              }
              label="Save to global"
              labelPlacement="start"
            />
            <HelpToolTip
              text={config.cms_strings.audiences.save_to_global_tooltip}
            />
          </Stack>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!enableSubmit || editDisabled}
          onClick={addAudienceWrapper}
          data-audience={JSON.stringify(data)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AudienceModal;
