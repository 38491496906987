import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import HelpToolTip from "../components/common/HelpToolTip";
import CustomFilter from "./CustomFilter";
import { useConfig } from "../context";

const MetricSettings: React.FC<any> = ({
  metricSettingsOpen,
  metricSettingsClose,
  metricIndex,
  rule,
  setRule,
  editDisabled,
}) => {
  const [filter, setFilter] = useState([
    { pointer: "", value: "", matching: "" },
  ]);
  const [department, setDepartment] = useState(null);
  const [departmentDisabled, setDepartmentDisabled] = useState(false);
  const [metricName, setMetricName] = useState("");
  const [winningDirection, setWinningDirection] = useState("positive");
  const [currentFilterTabIndex, setCurrentFilterTabIndex] = useState(0);
  const { config } = useConfig();

  useEffect(() => {
    const currentMetric = metricIndex > -1 ? rule.metrics[metricIndex] : false;
    if (currentMetric.custom) {
      return;
    }
    if (currentMetric && currentMetric.custom_filter) {
      setFilter(currentMetric.custom_filter);
    } else {
      setFilter([{ pointer: "", value: "", matching: "" }]);
    }

    if (currentMetric && currentMetric.department_filter) {
      setDepartment(currentMetric.department_filter);
    } else {
      setDepartment(null);
    }
    setMetricName(currentMetric.name);
    setWinningDirection(currentMetric.winning_direction);
    if (metricSettingsOpen) {
      setCurrentFilterTabIndex(0);
    }

    setDepartmentDisabled(currentMetric.department_filter_disabled === "true");
  }, [metricIndex, rule.metrics, metricSettingsOpen]);

  const [enableSubmit, setEnableSubmit] = useState(() => {
    const alteredFilter = filter.some(
      (i) => i.pointer.length > 0 || i.matching.length > 0 || i.value.length > 0
    );
    return alteredFilter
      ? filter.some(
          (i) =>
            i.pointer.length > 0 && i.matching.length > 0 && i.value.length > 0
        )
      : true;
  });

  const checkSubmit = useCallback(() => {
    const alteredFilter = filter.some(
      (i) => i.pointer.length > 0 || i.matching.length > 0 || i.value.length > 0
    );
    const shouldEnable = alteredFilter
      ? filter.some(
          (i) =>
            i.pointer.length > 0 && i.matching.length > 0 && i.value.length > 0
        )
      : true;
    setEnableSubmit(shouldEnable);
  }, [filter]);

  useEffect(() => {
    checkSubmit();
  }, [checkSubmit]);

  const settingsHandler = (e: any) => {
    switch (e.target.name) {
      case "department":
        setEnableSubmit(
          e.target.value !== rule.metrics[metricIndex].department_filter
        );
        setDepartment(e.target.value);
        break;
      case "metricname":
        setMetricName(e.target.value);
        break;
      case "winningdirection":
        setWinningDirection(e.target.value);
        break;
      default:
        break;
    }
  };

  const saveMetric = (e: any) => {
    const dpt = department && department > 0 ? department : null;
    setRule((prevState: any) => {
      const metrics = prevState.metrics.map((item: any, key: any) => {
        if (key === metricIndex) {
          return {
            ...item,
            name: metricName,
            custom_filter: filter,
            department_filter: dpt,
            winning_direction: winningDirection,
            department_filter_disabled: departmentDisabled,
          };
        } else {
          return item;
        }
      });
      return { ...prevState, metrics: metrics };
    });
    metricSettingsClose();
  };
  const handleFilterTabChange = (e: any, tabIndex: number) => {
    setCurrentFilterTabIndex(tabIndex);
  };

  const settings = () => {
    return (
      <>
        <Grid justifyContent={"stretch"} alignItems="center" container>
          <Grid
            item
            xs={4}
            sx={{ p: 2, pb: 0 }}
            container
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Typography variant="subtitle2">Metric name </Typography>
            </Grid>
            <Grid item>
              <HelpToolTip
                text={config.cms_strings.metrics.metric_name_tooltip}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={3}
            sx={{ p: 2, pb: 0 }}
            container
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Typography variant="subtitle2">Department (optional)</Typography>
            </Grid>
            <Grid item>
              <HelpToolTip
                text={
                  departmentDisabled
                    ? config.cms_strings.metrics
                        .department_filter_disabled_tooltip
                    : config.cms_strings.metrics.department_filter_tooltip
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid justifyContent={"stretch"} alignItems="flex-start" container>
          <Grid item xs={4} sx={{ p: 2, pt: 0 }}>
            <TextField
              autoFocus
              onChange={settingsHandler}
              margin="dense"
              value={metricName}
              name="metricname"
              type="text"
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>

          <Grid item xs={3} sx={{ p: 2, pt: 0 }}>
            <FormControl
              sx={{
                m: 1,
                width: "100%",
                "&.Mui-disabled": {
                  pointerEvents: "unset",
                  cursor: "not-allowed",
                },
              }}
              size="small"
            >
              <Select
                value={department || ""}
                onChange={settingsHandler}
                name="department"
                disabled={departmentDisabled}
                className={
                  departmentDisabled ? "department-filter-disabled" : ""
                }
              >
                {config.department_filters.map((item: any, key: any) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Dialog
      open={metricSettingsOpen}
      onClose={metricSettingsClose}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>Metric settings</DialogTitle>
      <DialogContent>
        <Tabs
          value={currentFilterTabIndex}
          onChange={handleFilterTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="General" />
          <Tab label="Custom filters" disabled={config.user.level < 50} />
        </Tabs>
        {currentFilterTabIndex === 0 ? (
          settings()
        ) : (
          <CustomFilter filter={filter} setFilter={setFilter} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={metricSettingsClose}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!enableSubmit || editDisabled}
          onClick={saveMetric}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MetricSettings;
