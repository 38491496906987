import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { FetchStatsLaunched } from "../helpers/FetchData";
import CenteredSpinner from "../components/common/CenteredSpinner";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
} from "recharts";
import YTicker from "./YTicker";
import ColorPalette from "../helpers/ColorPalette";
import { useTheme } from "@mui/material/styles";

const Launched: React.FC<any> = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [launchData, setLaunchData] = useState([]);
  const palette = ColorPalette(1);
  const theme = useTheme();

  const tooltipStyle = {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    borderRadius: "10px",
    fontSize: 12,
  };
  useEffect(() => {
    FetchStatsLaunched((output: any) => {
      setIsLoaded(true);
      setLaunchData(output.response);
    });
  }, []);

  const tooltipFormatter = (value: any) => {
    return [value, "Experiments started"];
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Experiments launched</Typography>
        {!isLoaded ? (
          <CenteredSpinner />
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={500}
              height={300}
              data={launchData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="month" tick={<YTicker />} />
              <CartesianGrid />
              <Tooltip
                formatter={tooltipFormatter}
                contentStyle={tooltipStyle}
                itemStyle={{ marginTop: 0, marginBottom: 0, padding: 0 }}
                cursor={{ fill: "#b8e18f", fillOpacity: 0.3 }}
              />

              <YAxis fontSize={12} />
              <Bar dataKey="experiments_started" fill={palette(0)} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default Launched;
