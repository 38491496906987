import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React, { useState, useEffect, useRef } from "react";
import { Container, Paper, Alert } from "@mui/material";
import { useSearchParams, useParams } from "react-router-dom";
import { ApiResponse, ExperimentResult } from "../types/Types";
import {
  FetchResult,
  FetchRule,
  FetchBucketedUsers,
} from "../helpers/FetchData";
import { HypothesisAndDetails } from "./HypothesisAndDetails";
import Loader from "../components/common/Loader";

import { useConfig } from "../context";
import mockData from "../helpers/Mockdata.json";
import ColorPalette from "../helpers/ColorPalette";
import { useTitle } from "../helpers/useTitle";
import { AbtestResult } from "./AbtestResult";
import { MabResult } from "./MabResult";
import { Toolbar } from "./Toolbar";

export interface IAppProps {}

const ResultsDashboard: React.FC<IAppProps> = (props) => {
  const { experimentId } = useParams();
  const [searchParams] = useSearchParams();
  const [isLoadingResult, setIsLoadingResult] = useState(true);
  const [isLoadingRule, setIsLoadingRule] = useState(true);
  const [isSegmented, setIsSegmented] = useState(false);
  const [ruleData, setRuleData] = useState<any>([]);
  const [segment, setSegment] = useState([]);
  const [appliedSegment, setAppliedSegment] = useState([]);
  const [bucketedVisitors, setBucketedVisitors] = useState({
    total: 0,
    variations: [],
  });
  const [hasResultData, setHasResultData] = useState(false);
  const [mockEnabled, setMockEnabled] = useState(false);
  const isFirstRender = useRef(true);

  const { config } = useConfig();

  const experimentObject: ExperimentResult = {
    experimentId: 0,
    name: "",
    metricType: "",
    type: "",
    metrics: [],
    bucketed_total: 0,
    bucketed_in: [],
    hypothesis: "",
    start_time: "",
    end_time: "",
    state: 0,
    attributes: [],
    decision_page: 0,
    variations: [],
    flag_id: 0,
  };
  const [experimentData, setExperimentData] =
    React.useState<ExperimentResult>(experimentObject);
  (window as any).experimentData = experimentData;
  (window as any).mock = mockEnabled;
  useEffect(() => {
    getRuleData();
    getBucketedUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getExperimentData();
    getBucketedUsers();
  }, [mockEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //setIsSegmented(segment.length > 0);
  }, [segment]);

  (window as any).experimentResult = experimentData;

  const applySegment = () => {
    setAppliedSegment([...segment]);
    setIsSegmented(segment.length > 0);
    getExperimentData();
    if (segment.length < 1) {
      getBucketedUsers();
    }
  };

  const getVariationColor = (variationId: number) => {
    const palette: any = ColorPalette(experimentData.variations.length);
    return palette(variationId);
  };
  const handleToggleMock = () => {
    setExperimentData(experimentObject);
    setMockEnabled((s: boolean) => !s);
  };

  const handleSegmentChange = (event: any) => {
    const type = event.type;
    const value = event.value;
    const newSegment: any = [...segment];
    const removed = newSegment.findIndex((e: any) => e.attribute === type);
    if (removed !== -1) {
      newSegment.splice(removed, 1);
    }
    if (value.length > 0) {
      newSegment.push({ attribute: type, value: value });
    }

    setSegment(newSegment);
  };

  const getRuleData = () => {
    setIsLoadingRule(true);
    if (mockEnabled) {
      setRuleData(mockData.rule.response[0]);
    } else {
      FetchRule((output: ApiResponse) => {
        setRuleData(output.response[0]);
        setIsLoadingRule(false);
        getExperimentResult(output.response[0]);
      }, Number(experimentId));
    }
  };
  const getExperimentData = () => {
    getExperimentResult(ruleData);
  };

  const updateSignificance = (metrics: any) => {
    const updatedMetrics: any = metrics.map((metric: any) => {
      const metricVariations = metric.variations.map((vari: any) => {
        const stats = getStatSig(metric, vari.variationId);
        vari.stats = stats;
        return vari;
      });
      metric.variations = metricVariations;
      return metric;
    });
    return updatedMetrics;
  };

  const mergeData = (data: any, results: any) => {
    if (results.users && results.users.length > 0) {
      handleBucketedVisitors(results.users);
    }
    results.metrics.sort((a: any, b: any) => a.metricIndex - b.metricIndex);
    if (
      !data.metrics.some((m: any) => m.is_guardrail) &&
      (data.type === "ab" || !data.type)
    ) {
      const index = data.metrics.length;
      config.guardrail_metrics.forEach((m: any, i: number) => {
        m.index = i + index;
        m.is_guardrail = true;
        data.metrics.push(m);
      });
    }

    data.metrics.forEach((i: any, index: number, object: any) => {
      if (i.is_success) {
        i.category = "success";
      } else if (i.is_guardrail) {
        i.category = "guardrail";
      } else {
        i.category = "monitoring";
      }
      const resultMetric = results.metrics.find(
        (v: any) => i.index === v.metricIndex
      );
      if (resultMetric) {
        i.variations = resultMetric.variations;
      } else {
        i.variations = [];
      }
    });

    const metricsWithStats: any = updateSignificance(data.metrics);
    data.metrics = metricsWithStats;
    setExperimentData(data);
    setHasResultData(true);
  };

  const getExperimentResult = (data: any) => {
    setIsLoadingResult(true);
    setBucketedVisitors({ total: 0, variations: [] });
    if (mockEnabled) {
      const results = mockData.results.response.results;
      mergeData(data, results);
      setIsLoadingResult(false);
    } else {
      FetchResult(
        searchParams.get("rid") ? searchParams.get("rid") : experimentId,
        segment.length > 0 ? segment : false,
        searchParams.get("s") ? searchParams.get("s") : null,
        data.type,
        (output: ApiResponse) => {
          if (
            output.status === 200 &&
            output.response.hasOwnProperty("results")
          ) {
            const results = output.response.results;
            mergeData(data, results);
          }
          if (output.status === 204) {
            setHasResultData(false);
            setExperimentData(data);
          }
          setIsLoadingResult(false);
        }
      );
    }
  };

  useTitle(experimentData.name);

  const getBucketedUsers = () => {
    if (mockEnabled) {
      const bucketed: any = {
        variations: [
          {
            variationId: "1",
            number_bucketed: 11480,
          },
          {
            variationId: "0",
            number_bucketed: 614,
          },
        ],
        total: 12094,
      };
      setBucketedVisitors(bucketed);
    } else {
      setBucketedVisitors({ total: 0, variations: [] });
      FetchBucketedUsers(
        (output: any) => {
          if (output.status === 200) {
            handleBucketedVisitors(output.response);
          } else {
            setBucketedVisitors({
              variations: [],
              total: -1,
            });
          }
        },
        searchParams.get("rid")
          ? Number(searchParams.get("rid"))
          : Number(experimentId),
        searchParams.get("s"),
        segment.length > 0 ? segment : false
      );
    }
  };

  const handleBucketedVisitors = (data: any) => {
    const bucketed: any = {};
    bucketed.total = data.reduce(
      (acc: any, curr: any) => acc + curr.number_bucketed,
      0
    );
    bucketed.variations = data;
    setBucketedVisitors(bucketed);
  };

  const getVariationName = (variationId: number) => {
    const variation: any = experimentData.variations.find(
      (variation: { id: number; name: string }) => variation?.id === variationId
    );
    return variation ? variation.name : "";
  };

  const getStatSig = (metric: any, variationId: number) => {
    const out = {
      statSig: false,
      statColor: "",
      statBgColor: "",
      isWinner: false,
    };
    if (variationId === 0) {
      return out;
    }

    const variationData = metric.variations[variationId];
    out.statSig =
      variationData.significanse !== null && variationData.significanse < 0.05
        ? true
        : false;
    const isWinner =
      (metric.winning_direction === "positive" &&
        variationData.pct_change > 0) ||
      (metric.winning_direction === "negative" && variationData.pct_change < 0)
        ? true
        : false;
    out.isWinner = out.statSig && isWinner ? true : false;
    return out;
  };

  return isLoadingRule || !config.cms_strings ? (
    <Loader />
  ) : (
    <div>
      <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
        {/* DETAILS */}
        <Toolbar
          mockEnabled={mockEnabled}
          handleToggleMock={handleToggleMock}
          isSegmented={isSegmented}
          segment={segment}
          appliedSegment={appliedSegment}
          handleSegmentChange={handleSegmentChange}
          applySegment={applySegment}
          experimentData={experimentData}
          config={config}
          isLoadingResult={isLoadingResult}
        />
        <HypothesisAndDetails
          {...experimentData}
          ruleData={ruleData}
          bucketedVisitors={bucketedVisitors}
          config={config}
        />
        {isLoadingResult ? (
          <Loader />
        ) : (experimentData.variations &&
            experimentData.variations.length > 0 &&
            hasResultData) ||
          mockEnabled ? (
          experimentData.type === "mab" ? (
            <MabResult
              experimentData={experimentData}
              bucketedVisitors={bucketedVisitors}
              getVariationColor={getVariationColor}
              getVariationName={getVariationName}
              config={config}
            />
          ) : (
            <AbtestResult
              experimentData={experimentData}
              bucketedVisitors={bucketedVisitors}
              getVariationColor={getVariationColor}
              getVariationName={getVariationName}
              isSegmented={isSegmented}
              mockEnabled={mockEnabled}
            />
          )
        ) : (
          <Paper sx={{ mt: 2 }}>
            <Alert severity="info">
              No results available. Please try later.
            </Alert>
          </Paper>
        )}
      </Container>
    </div>
  );
};

export default ResultsDashboard;
