import React from "react";
import ReactDOM from "react-dom";
import { StyledEngineProvider } from "@mui/material";
import "./index.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Start from "./Start";
import Flags from "./Flags";
import Flag from "./FlagDetail";
import ViewRule from "./FlagViewRule";
import FlagSettings from "./FlagSettings";
import ResultsDashboard from "./ResultsDashboard";
import PageLayout from "./components/layout/PageLayout";
import Results from "./Results";
import Pages from "./CMS/Pages";
import RedirectToFlagViewRule from "./RedirectToFlagViewRule";
import { publicClientApplication } from "./helpers/Auth";
import { MsalProvider } from "@azure/msal-react";
import ProgramReporting from "./ProgramReporting";
//context provider.
import ConfigProvider from "./context";
import { ConfirmProvider } from "material-ui-confirm";
import CmsAdmin from "./Admin/CmsAdmin";
import { PageView } from "./Tracking/";
import Watchtower from "./Watchtower";

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <React.StrictMode>
      <MsalProvider instance={publicClientApplication}>
        <ConfigProvider>
          <BrowserRouter>
            <ConfirmProvider>
              <PageLayout>
                <Routes>
                  <Route path="/">
                    <Route index path="/" element={<Start />} />
                  </Route>
                  <Route path="/results">
                    <Route index path="/results" element={<Results />} />
                    <Route
                      path=":experimentId"
                      element={<ResultsDashboard />}
                    />
                  </Route>
                  <Route path="/programreporting">
                    <Route
                      index
                      path="/programreporting"
                      element={<ProgramReporting />}
                    />
                  </Route>
                  <Route path="/watchtower">
                    <Route index path="/watchtower" element={<Watchtower />} />
                  </Route>
                  <Route path="/features">
                    <Route index path="/features" element={<Flags />} />
                    <Route path=":flagId" element={<Flag />} />
                    <Route path=":flagId/:ruleId" element={<ViewRule />} />
                    <Route path=":flagId/settings" element={<FlagSettings />} />
                  </Route>
                  <Route path="/pages">
                    <Route index path="/pages" element={<Pages />} />
                    <Route path="/pages:page" element={<Pages />} />
                  </Route>
                  <Route
                    path="/experiment/:ruleId"
                    element={<RedirectToFlagViewRule />}
                  />
                  <Route path="/admin">
                    <Route index path="/admin/cms" element={<CmsAdmin />} />
                  </Route>
                </Routes>
              </PageLayout>
            </ConfirmProvider>
            <PageView />
          </BrowserRouter>
        </ConfigProvider>
      </MsalProvider>
    </React.StrictMode>
  </StyledEngineProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
