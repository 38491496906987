import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { FetchStatsLive } from "../helpers/FetchData";
import moment from "moment";
import CenteredSpinner from "../components/common/CenteredSpinner";
import { useConfig } from "../context";

const CurrentLive: React.FC<any> = ({ summarize }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [liveData, setLiveData] = useState([]);
  const [liveTableData, setLiveTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const touchpoints = ["desktop", "mobile web", "ios", "android"];
  const { config } = useConfig();

  useEffect(() => {
    FetchStatsLive((output: any) => {
      setIsLoaded(true);
      setLiveData(output.response);
      setLiveTableData(output.response);
    });
  }, []);

  const handleOpen = () => {
    if (summarize === true) {
      navigate("/programreporting");
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getTouchpoints = (item: any) => {
    const tp = item.attributes
      .filter((i: any) => i.attribute === "touchpoint")
      .flatMap((a: any) => a.values);
    return tp.join(", ").toLowerCase();
  };

  const getBrandName = (brand_id: number) => {
    const brand = config?.brands?.find((brand: any) => brand.id === brand_id);
    const result = brand ? brand["name"] : "unknown";
    return result;
  };

  const handleTouchpointFilter = (e: any) => {
    const value = e.target.value;
    if (value === "touchpoint") return setLiveTableData(liveData);
    const data = liveData.filter((i: any) => {
      const tp = i.attributes.filter(
        (t: any) =>
          t.attribute === "touchpoint" &&
          t.values.some((a: any) => a.toLowerCase() === value.toLowerCase())
      );
      return tp.length > 0 ? tp : false;
    });
    setLiveTableData(data);
  };
  const handleFilter = (e: any) => {
    const { value, name } = e.target;
    if (value === "all") return setLiveTableData(liveData);
    const data = liveData.filter((i: any) => i[name] === value);
    setLiveTableData(data);
  };

  const liveExperimentsList = (limit: number) => {
    return (
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className="rule_id">ID</TableCell>
              <TableCell>Experiment name</TableCell>
              <TableCell sx={{ width: 150 }}>Started</TableCell>
              <TableCell sx={{ width: 130 }}>
                {summarize ? (
                  "Brand"
                ) : (
                  <FormControl sx={{ width: 120 }} size="small">
                    <InputLabel id="brand-select-label">Brand</InputLabel>
                    <Select
                      labelId="brand-select-label"
                      label="Brand"
                      id="brand-select"
                      defaultValue="all"
                      size="small"
                      name="brand_id"
                      onChange={handleFilter}
                      sx={{ maxWidth: "130px" }}
                    >
                      <MenuItem key="all" value="all">
                        All
                      </MenuItem>
                      {config.brands.map((i: any) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell sx={{ width: 130 }}>
                {summarize ? (
                  "Team"
                ) : (
                  <FormControl sx={{ width: 120 }} size="small">
                    <InputLabel id="team-select-label">Team</InputLabel>
                    <Select
                      labelId="team-select-label"
                      label="Team"
                      id="team-select"
                      defaultValue="all"
                      size="small"
                      name="team_name"
                      onChange={handleFilter}
                      sx={{ maxWidth: "130px" }}
                    >
                      <MenuItem key="all" value="all">
                        All
                      </MenuItem>
                      {config.teams.map((i: any) => (
                        <MenuItem key={i.id} value={i.name}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell sx={{ width: 130 }}>
                {summarize ? (
                  "Touchpoint"
                ) : (
                  <FormControl sx={{ width: 120 }} size="small">
                    <InputLabel id="touchpoint-select-label">
                      Touchpoint
                    </InputLabel>
                    <Select
                      labelId="touchpoint-select-label"
                      label="Touchpoint"
                      id="touchpoint-select"
                      defaultValue="touchpoint"
                      size="small"
                      name="touchpoint"
                      onChange={handleTouchpointFilter}
                      sx={{ textTransform: "capitalize", maxWidth: "130px" }}
                    >
                      <MenuItem key="touchpoint" value="touchpoint">
                        All
                      </MenuItem>
                      {touchpoints.map((i: any) => (
                        <MenuItem
                          key={i}
                          value={i}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell sx={{ width: 130 }}>
                {summarize ? (
                  "Page"
                ) : (
                  <FormControl sx={{ width: 120 }} size="small">
                    <InputLabel id="page-select-label">Page</InputLabel>
                    <Select
                      labelId="page-select-label"
                      label="Page"
                      id="page-select"
                      defaultValue="all"
                      size="small"
                      name="page"
                      onChange={handleFilter}
                      sx={{ maxWidth: "130px" }}
                    >
                      <MenuItem key="all" value="all">
                        All
                      </MenuItem>
                      {config.decision_pages.map((i: any) => (
                        <MenuItem key={i.id} value={i.name}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell sx={{ width: 100 }}>Variations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {liveTableData.slice(0, limit).map((item: any) => (
              <TableRow
                className="experiment-list-row"
                key={item.rule_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={(e) => {
                  navigate(
                    `/results/${item.rule_id}?s=${new Date(
                      item.rule_started
                    ).getTime()}`
                  );
                }}
              >
                <TableCell component="th" scope="row" className="rule_id">
                  <Typography variant="caption">{item.rule_id}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.rule_name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {moment(item.rule_started).format("YYYY-MM-DD HH:mm")}
                </TableCell>
                <TableCell component="th" scope="row">
                  {getBrandName(item.brand_id)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.team_name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ textTransform: "capitalize" }}
                >
                  {getTouchpoints(item)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.page}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.variations}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5">
            Live experiments: {liveData.length}
          </Typography>
          {isLoaded ? liveExperimentsList(5) : <CenteredSpinner />}
          <Typography variant="body2" color="textSecondary" align="right">
            <Button disabled={liveTableData.length < 6} onClick={handleOpen}>
              More...
            </Button>
          </Typography>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle>Live experiments</DialogTitle>
        <DialogContent>{liveExperimentsList(999)}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CurrentLive;
