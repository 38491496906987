import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Paper,
  Stack,
  Grid,
  Button,
  Typography,
  Tooltip,
  Divider,
  Alert,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import TuneIcon from "@mui/icons-material/Tune";
import CreateIcon from "@mui/icons-material/Create";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import SaveIcon from "@mui/icons-material/Save";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SortableList, { SortableItem } from "react-easy-sort";
import { useConfig } from "./../context";
import MetricSettings from "./MetricSettings";
import CustomMetricDialog from "./CustomMetricDialog";
import HelpToolTip from "../components/common/HelpToolTip";
import GlobalMetrics from "./GlobalMetrics";
import { FetchMetricSet, UpdateMetricSet } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";
import { enqueueSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import { arrayMoveImmutable } from "array-move";

const Metrics: React.FC<any> = ({
  rule,
  setRule,
  team,
  editDisabled,
  metricError,
}) => {
  const [metricsListOpen, setMetricsListOpen] = useState(false);
  const [customMetricOpen, setCustomMetricOpen] = useState(false);
  const [metricSettingsOpen, setMetricSettingsOpen] = useState(false);

  const [hasMaxMetrics, setHasMaxMetrics] = useState(false);
  const [maxNum, setMaxNum] = useState(0);
  const [metricIndex, setMetricIndex] = useState(-1);
  const { config, setConfig } = useConfig();
  const confirm = useConfirm();
  (window as any).metricIndex = metricIndex;
  useEffect(() => {
    const max = rule.type === "mab" ? 1 : 10;
    setMaxNum(max);
    setHasMaxMetrics(rule.metrics.length >= max);
    setMetricIndex(-1);
  }, [rule]);

  const hasOwnGuardrails = () => {
    return rule.metrics.some(
      (item: any) => item.is_guardrail && Number(rule.state) >= 30
    );
  };
  const guardrailMetrics = () => {
    if (rule.brand_id === 0) {
      return [];
    }
    if (hasOwnGuardrails()) {
      return rule.metrics.filter((item: any) => item.is_guardrail);
    } else {
    }
    const brand: any = config.brands.find(
      (brand: any) => brand.id === rule.brand_id
    );
    return config.guardrail_metrics.filter((item: any) =>
      brand.guardrails.includes(item.id)
    );
  };

  const handleMetricsListClose = () => {
    setMetricsListOpen(false);
  };
  const handleMetricSettingsClose = () => {
    setMetricIndex(-1);
    setMetricSettingsOpen(false);
  };
  const successHandler = (e: any) => {
    setRule((prevState: any) => {
      const metrics = prevState.metrics;
      metrics[e.target.dataset.index].is_success = e.target.checked;
      const data = {
        ...prevState,
        metrics: metrics,
      };
      return data;
    });
  };

  const orderMetrics = (metrics: []) => {
    return metrics.map((m: any, index) => {
      return { ...m, index: index };
    });
  };
  const removeMetric = (e: any) => {
    setRule((prevState: any) => {
      const metrics = prevState.metrics;
      metrics.splice(e.currentTarget.dataset.index, 1);
      const ordered: any = orderMetrics(metrics);
      const data = {
        ...prevState,
        metrics: ordered,
      };
      return data;
    });
  };

  const editMetricWrapper = (e: any) => {
    const index = Number(e.currentTarget.dataset.index);
    setMetricIndex(index);

    if (rule.metrics[index].custom) {
      setCustomMetricOpen(true);
    } else {
      setMetricSettingsOpen(true);
    }
  };
  const toggleBackdrop = (b: boolean) => {
    setConfig((prevState: any) => {
      return {
        ...prevState,
        showBackdrop: b,
      };
    });
  };

  const getRegionId = (regionKey: any) => {
    const region = config?.regions?.find(
      (region: any) => region.region_key === regionKey
    );
    const result = region ? region["id"] : 0;
    return result;
  };

  const getMetricSet = () => {
    toggleBackdrop(true);
    FetchMetricSet(
      (output: ApiResponse) => {
        if (output?.status !== 200) {
          enqueueSnackbar(output.message, { variant: "error" });
          toggleBackdrop(false);
        } else {
          toggleBackdrop(false);
          if (output.response.length > 0) {
            setRule((prevState: any) => {
              const data = {
                ...prevState,
                metrics: output.response[0].metrics,
              };
              return data;
            });
          } else {
            enqueueSnackbar(config.cms_strings.metrics.metricset_notfound, {
              variant: "warning",
            });
          }
        }
      },
      Number(team),
      getRegionId(rule.region_key)
    );
  };

  const setMetricSet = () => {
    confirm({
      title: "Update metricset",
      description: config.cms_strings.metrics.metricset_confirm,
      confirmationButtonProps: { color: "error", variant: "contained" },
    })
      .then(() => {
        toggleBackdrop(true);
        const data = {
          teamId: Number(team),
          regionId: getRegionId(rule.region_key),
          metrics: rule.metrics,
        };
        UpdateMetricSet((output: ApiResponse) => {
          if (output?.status !== 200) {
            enqueueSnackbar(output.message, { variant: "error" });
            toggleBackdrop(false);
          } else {
            enqueueSnackbar("Metricset saved", { variant: "success" });
            toggleBackdrop(false);
          }
        }, data);
      })
      .catch(() => {
        console.log("Cancelled");
      });
  };

  const addCustomMetricWrapper = () => {
    setMetricIndex(-1);
    setMetricsListOpen(false);
    setCustomMetricOpen(true);
    //addMetricHandler(e)
  };

  const customMetricClose = () => {
    setMetricIndex(-1);
    setCustomMetricOpen(false);
  };

  const hasMaxSuccess = () => {
    let numSuccess = rule.metrics.filter((e: any) => {
      return e.is_success;
    });
    return numSuccess.length > 1;
  };

  const getGuardrailDescription = (guardrail: any) => {
    return `Captures ${
      guardrail.direction === "less" ? "a decrease" : "an increase"
    } in the ${guardrail.name.toLowerCase()}`;
  };
  const getDirectionArrow = (metric: any) => {
    return metric.winning_direction === "positive" ? (
      <NorthEastIcon fontSize="small" sx={{ mt: "-1px", fontSize: "1rem" }} />
    ) : (
      <SouthEastIcon fontSize="small" />
    );
  };

  const sortMetrics = (oldIndex: number, newIndex: number) => {
    if (rule.type === "mab") {
      return;
    }
    setRule((prevState: any) => {
      const metrics = prevState.metrics;
      const sortedMetrics = arrayMoveImmutable(metrics, oldIndex, newIndex).map(
        (a: any, i: number) => {
          return { ...a, index: i };
        }
      );

      const data = {
        ...prevState,
        metrics: sortedMetrics,
      };
      return data;
    });
  };

  const guardrailItems = (guardrailMetrics: []) => {
    if (guardrailMetrics.length > 0) {
      return guardrailMetrics.map((item: any, key: any) => (
        <Grid xs={12} sm={6} md={4} item key={key}>
          <Paper sx={{ p: 1, borderRight: "5px solid rgba(211, 47, 47, 0.5)" }}>
            <Grid container>
              <Grid container item xs>
                <Grid xs={12} item>
                  <Typography variant="button" display="inline-block">
                    {key + 1}.{" "}
                  </Typography>
                  <Typography variant="subtitle2" display="inline-block">
                    {item.name || "Metric name"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    {getGuardrailDescription(item)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                {(item.custom_filter && item.custom_filter.length > 0) ||
                (item.department_filter && item.department_filter !== 11) ? (
                  <Tooltip title="Filters applied" placement="top-start">
                    <TuneIcon />
                  </Tooltip>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ));
    } else {
      return <></>;
    }
  };

  const metricItems = (ruleMetrics: []) => {
    if (ruleMetrics.length > 0) {
      return ruleMetrics.map(
        (item: any, key: any) =>
          !item.is_guardrail && (
            <SortableItem key={key}>
              <Grid xs={12} sm={6} md={4} item key={key}>
                <Paper
                  sx={{
                    p: 1,
                    cursor:
                      editDisabled || rule.type === "mab" ? "auto" : "move",
                  }}
                >
                  <Grid container>
                    <Grid container item xs>
                      <Grid xs={12} item>
                        <Stack direction="row" gap={0} alignItems="center">
                          <Typography
                            variant="subtitle2"
                            display="inline-block"
                          >
                            {key + 1}.{" "}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            display="inline-block"
                          >
                            {item.name || "Metric name"}
                          </Typography>
                          {item.winning_direction
                            ? getDirectionArrow(item)
                            : ""}
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Checkbox
                          inputProps={{ "data-index": key } as any}
                          checked={item.is_success || false}
                          onChange={successHandler}
                          name={key.toString()}
                          disabled={
                            rule.type === "mab" ||
                            (!item.is_success && hasMaxSuccess()) ||
                            editDisabled
                          }
                        />
                        <Typography variant="caption">
                          Success metric
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      {(item.custom_filter && item.custom_filter.length > 0) ||
                      (item.department_filter &&
                        item.department_filter !== 11) ? (
                        <Tooltip title="Filters applied" placement="top-start">
                          <TuneIcon />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid container item xs={1} justifyContent="flex-end">
                      <Grid item xs={12}>
                        <Button
                          data-index={key}
                          onClick={editMetricWrapper}
                          sx={{ p: 0, justifyContent: "right", minWidth: 0 }}
                        >
                          <CreateIcon />
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          data-index={key}
                          onClick={removeMetric}
                          sx={{ p: 0, justifyContent: "right", minWidth: 0 }}
                          disabled={editDisabled}
                        >
                          <DeleteIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </SortableItem>
          )
      );
    } else {
      return (
        <Grid item key="1212e123321">
          <Paper sx={{ p: 1 }}>
            <Grid container>
              <Grid xs={12} item>
                <Typography variant="subtitle2">No metrics added</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }
  };

  return (
    <>
      <Stack spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h6">Metrics</Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={hasMaxMetrics || editDisabled}
              onClick={(e) => {
                setMetricsListOpen(true);
              }}
              size="large"
            >
              <AddBoxIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          {!(rule.type === "mab") ? (
            <Grid item xs="auto" marginLeft="auto">
              <IconButton
                disabled={
                  rule.metrics.length > 0 ||
                  config.user.level < 25 ||
                  (config.user.region.length > 0 &&
                    config.user.region !== rule.region_key)
                }
                onClick={getMetricSet}
                size="large"
              >
                <CloudDownloadIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                disabled={
                  rule.metrics.length === 0 ||
                  config.user.level < 25 ||
                  (config.user.region.length > 0 &&
                    config.user.region !== rule.region_key)
                }
                onClick={setMetricSet}
                size="large"
              >
                <SaveIcon fontSize="inherit" />
              </IconButton>
              <HelpToolTip
                text={config.cms_strings.metrics.metricset_tooltip}
                link={config.cms_strings.metrics.metricset_tooltip_link}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <SortableList
          draggable={rule.type === "mab" ? false : true}
          onSortEnd={sortMetrics}
          className="list"
          draggedItemClassName="dragged"
          allowDrag={rule.type === "mab" ? false : !editDisabled}
        >
          <Grid container spacing={2} sx={{ marginTop: "-20px!important" }}>
            {metricItems(rule.metrics)}
          </Grid>
        </SortableList>
      </Stack>
      {metricError.length > 0 ? (
        <Alert className="metrics_error" severity="error">
          {metricError}
        </Alert>
      ) : null}
      {hasMaxMetrics && !(Number(rule.state) >= 30) ? (
        <Alert className="metrics_error" severity="warning">
          Max number of metrics added
        </Alert>
      ) : null}
      {rule.type !== "mab" ? (
        <>
          <Divider />

          <Stack spacing={2} sx={{ mb: "20px", mt: "20px" }}>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="h6">Guardrail metrics</Typography>
              </Grid>
              <Grid item>
                <HelpToolTip
                  text={
                    config.cms_strings.experiments.guardrail_metrics_tooltip
                  }
                  link={
                    config.cms_strings.experiments
                      .guardrail_metrics_tooltip_link
                  }
                />
              </Grid>
            </Grid>
            <SortableList
              onSortEnd={sortMetrics}
              className="list"
              draggedItemClassName="dragged"
            >
              <Grid container spacing={2} sx={{ marginTop: "-20px!important" }}>
                {guardrailItems(guardrailMetrics())}
              </Grid>
            </SortableList>
          </Stack>
        </>
      ) : (
        <></>
      )}

      <GlobalMetrics
        metricsListOpen={metricsListOpen}
        handleMetricsListClose={handleMetricsListClose}
        addCustomMetricWrapper={addCustomMetricWrapper}
        setRule={setRule}
        rule={rule}
        maxNum={maxNum}
      />

      <MetricSettings
        metricSettingsOpen={metricSettingsOpen}
        metricSettingsClose={handleMetricSettingsClose}
        metricIndex={metricIndex}
        rule={rule}
        setRule={setRule}
        editDisabled={editDisabled}
      />
      <CustomMetricDialog
        customMetricOpen={customMetricOpen}
        customMetricClose={customMetricClose}
        metricIndex={metricIndex}
        rule={rule}
        setRule={setRule}
      />
    </>
  );
};

export default Metrics;
