import React from "react";
import { Typography, Grid, FormControl, Select, MenuItem } from "@mui/material";
import HelpToolTip from "../components/common/HelpToolTip";
import { useConfig } from "../context";

const ChangeDecisionPage: React.FC<any> = ({
  currentPage,
  handlePageChange,
  editDisabled,
}) => {
  const { config } = useConfig();

  if (Object.keys(config).length === 0) return <div>Loading pages</div>; // Waiting for config
  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs="auto">
        <Typography variant="caption">Experiment page</Typography>
      </Grid>
      <Grid item xs={2}>
        <HelpToolTip
          text={config.cms_strings.experiments.decision_page_tooltip}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          sx={{ m: 1, minWidth: 120, marginLeft: "0px!important" }}
          size="small"
        >
          <Select
            value={currentPage}
            onChange={handlePageChange}
            name="decision_page"
            defaultValue={0}
            disabled={editDisabled}
          >
            <MenuItem key={0} value={0}>
              Select page
            </MenuItem>
            {config.decision_pages.map((item: { name: string; id: number }) => {
              return (
                <MenuItem key={item.name} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ChangeDecisionPage;
