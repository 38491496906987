import React from "react";
import {
  Typography,
  Link,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useConfig } from "../context";

const CustomFilter: React.FC<any> = ({ filter, setFilter }) => {
  const { config } = useConfig();
  const addFilter = () => {
    setFilter((prevState: any) => {
      const filters = [...prevState];
      filters.push({ pointer: "", value: "", matching: "" });
      return filters;
    });
  };
  const removeFilter = () => {
    setFilter((prevState: any) => {
      const filters = [...prevState];
      filters.pop();
      if (filters.length < 1) {
        setFilter([
          {
            pointer: "",
            value: "",
            matching: "",
          },
        ]);
      }
      return filters;
    });
  };
  const changeHandler = (e: any) => {
    setFilter((prevState: any) => {
      const filters = [...prevState];
      filters[e.target.dataset.index][e.target.name] = e.target.value;
      return filters;
    });
  };

  const changeMatchHandler = (e: any) => {
    const filterIndex = Number(e.target.name);
    setFilter((prevState: any) => {
      const filters = [...prevState];
      filters[filterIndex].matching = e.target.value;
      return filters;
    });
  };

  return (
    <>
      <Typography sx={{ p: 2, fontStyle: "italic" }} variant="body2">
        {config.cms_strings.metrics.custom_filter_info}
        <Link
          target="_blank"
          rel="noreferrer"
          href={config.cms_strings.metrics.custom_filter_info_link}
        >
          Read more.
        </Link>
        .{" "}
      </Typography>
      {filter.map((item: any, key: any) => (
        <Grid
          justifyContent={"stretch"}
          alignItems="center"
          container
          key={key}
          sx={{
            "&:last-child svg": { display: "inline-block!important" },
            "&:last-child .MuiChip-root": { display: "none!important" },
          }}
        >
          <Grid item xs={3} sx={{ p: 2 }}>
            <TextField
              onChange={changeHandler}
              autoFocus
              margin="dense"
              value={item.pointer}
              name="pointer"
              label="Pointer"
              type="text"
              fullWidth
              inputProps={{ "data-index": key }}
              size="small"
            />
          </Grid>
          <Grid item xs={3} sx={{ p: 2 }}>
            <FormControl sx={{ mt: 1, mb: 0.5, width: "100%" }} size="small">
              <InputLabel id="demo-simple-select-label">Matching</InputLabel>
              <Select
                value={item.matching}
                onChange={changeMatchHandler}
                name={key.toString()}
                label="Matching"
                defaultValue={0}
              >
                <MenuItem key={0} value={0}>
                  Select matching
                </MenuItem>
                {/* TODO: Get match types from DB */}
                <MenuItem key={1} value="in">
                  In
                </MenuItem>
                <MenuItem key={2} value="not in">
                  Not in
                </MenuItem>
                <MenuItem key={3} value="re.match">
                  Regex
                </MenuItem>
                {/* Temporary disabled 
                <MenuItem key={4} value='=='>==</MenuItem>          
                <MenuItem key={5} value='!='>!=</MenuItem>          
                <MenuItem key={6} value='>'>Greater than</MenuItem>          
                <MenuItem key={7} value='<'>Less than</MenuItem>          
                */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} sx={{ p: 2 }}>
            <TextField
              onChange={changeHandler}
              margin="dense"
              value={item.value}
              name="value"
              label="Value"
              type="text"
              fullWidth
              inputProps={{ "data-index": key }}
              size="small"
            />
          </Grid>
          <Grid
            item
            sx={{ px: 2, alignItems: "center", display: "flex" }}
            alignItems="center"
          >
            <AddCircleOutlineIcon
              onClick={addFilter}
              sx={{ display: "none", cursor: "pointer" }}
            ></AddCircleOutlineIcon>
            {key > 0 ? (
              <RemoveCircleOutlineIcon
                onClick={removeFilter}
                sx={{ display: "none", cursor: "pointer" }}
              ></RemoveCircleOutlineIcon>
            ) : (
              <></>
            )}
            <Chip
              size="small"
              label="AND"
              variant="filled"
              sx={{ "& span": { px: "4px" } }}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default CustomFilter;
