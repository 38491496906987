import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Metric } from "../types/Types";
import { useTheme } from "@mui/material/styles";

export const GuardrailMetricsTable = (props: {
  guardrailMetrics: Metric[];
  textColor: string;
  variationId: number;
}) => {
  const theme = useTheme();
  const result = props.guardrailMetrics
    .map((metric) => {
      const variation = metric.variations.filter(
        (variation) => variation.variationId === props.variationId
      );
      if (variation.length === 0) return null;
      return {
        name: metric.name,
        ...variation[0],
      };
    })
    .filter((item) => item !== null);

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {result.map((row: any) => {
            return (
              <TableRow
                key={row?.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row" sx={{ fontSize: "14px" }}>
                  {row?.name}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontSize: "16px",
                    color: row.stats.statSig
                      ? row.stats.isWinner
                        ? theme.palette.success.main
                        : theme.palette.error.main
                      : theme.palette.text.secondary,
                  }}
                >
                  {row?.pct_change?.toFixed(2)}%
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
