import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import GeneralFields from "./GeneralFields";
import VariationsFields from "./VariationsFields";
import ChangeState from "./ChangeState";
import { useConfig } from "./../context";

import { ApiResponse } from "../types/Types";

import {
  FetchFlag,
  UpdateRule,
  UpdateRuleState,
  FetchAttributes,
  CreateAudience,
} from "../helpers/FetchData";
import { evenDistributedAllocataion, isLiveValid } from "../helpers/Helpers";
import { enqueueSnackbar } from "notistack";

import {
  Button,
  Stack,
  Grid,
  Divider,
  Alert,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import ButtonBack from "../components/common/BackButton";
import Metrics from "../Metrics/Metrics";
import Attributes from "../Attributes/Attributes";
import { useConfirm } from "material-ui-confirm";
import MoreMenu from "./MoreMenu";
import Scheduler from "./Scheduler";
import { cms_strings } from "../CMS/cms_strings";
import TrackingPromotionCreativeField from "./fields/TrackingPromotionCreativeField";

const UpdateMab: React.FC<any> = ({ fullRuleData, initData, updateParent }) => {
  const [ruleData, setRuleData] = useState({
    variations: [],
    state: "",
    decision_page: "",
    id: 0,
    metrics: [],
    attributes: [],
    name: "",
    description: "",
    hypothesis: "",
    region_key: "",
    schedule: [],
    brand_id: 0,
    planned_start_date: null,
  });
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [metricError, setMetricError] = useState("");
  const { config, setConfig } = useConfig();
  const { flagId, ruleId } = useParams();
  const [editDisabled, setEditDisabled] = useState(false);
  const [flagFetched, setFlagFetched] = useState(false);
  const [lastAddedVariation, setLastAddedVariation] = useState(0);
  const [saveGlobal, setSaveGlobal] = useState(false);
  const [flagArchived, setFlagArchived] = useState(false);

  const confirm = useConfirm();

  const getSliderAllocation = useCallback(() => {
    const [{ endOfRange }] = fullRuleData.distribution
      ? fullRuleData.distribution.slice(-1)
      : [{ endOfRange: 10000 }]; // workaround for broken rules in db
    setSliderAllocation(endOfRange / 100);
  }, [fullRuleData]);

  const checkRuleUpdated = useCallback(() => {
    if (flagArchived) {
      setEnableUpdate(false);
    } else if (config.user.level < 25) {
      setEnableUpdate(false);
    } else if (
      config.user.region.length > 0 &&
      config.user.region !== ruleData.region_key
    ) {
      setEnableUpdate(false);
    } else {
      setEnableUpdate(initData !== JSON.stringify(ruleData));
      setMetricError("");
    }
  }, [flagArchived, config, ruleData, initData]);

  useEffect(() => {
    checkRuleUpdated();
  }, [checkRuleUpdated]);

  (window as any).ruleData = ruleData;
  (window as any).fullruleData = fullRuleData;
  useEffect(() => {
    if (Object.keys(fullRuleData).length === 1) return;
    setRuleData(fullRuleData);
    getSliderAllocation();
    setEditDisabled(fullRuleData.state >= 30);
  }, [fullRuleData, getSliderAllocation]);

  const [sliderAllocation, setSliderAllocation] = useState<number>(100);

  const onChangeHandler = (e: any) => {
    const { name, value, type } = e.target;
    let newValue: any;
    switch (type) {
      case "moment":
        newValue =
          value && value.isValid()
            ? name === "planned_start_date"
              ? value.startOf("day").format("YYYY-MM-DD HH:mm:ss")
              : value.endOf("day").format("YYYY-MM-DD HH:mm:ss")
            : null;
        break;
      case "number":
        newValue = Number(value);
        break;
      default:
        newValue = value;
        break;
    }

    setRuleData((prevState: any) => {
      const data = { ...prevState, [name]: newValue };
      return data;
    });
  };

  const validAllocation = (allocation: number) => {
    const [{ endOfRange }] = fullRuleData.distribution
      ? fullRuleData.distribution.slice(-1)
      : [{ endOfRange: 10000 }];
    const minAllocation = endOfRange / 100;
    const correctState = fullRuleData.state < 30;
    const correctAllocation = minAllocation <= allocation;
    return correctState || correctAllocation;
  };
  const handleSliderChange = (e: any) => {
    const allocation = e.target.value;
    if (validAllocation(allocation)) {
      setSliderAllocation(allocation);
    }
  };
  const handleSliderCommitted = (e: any, value: number) => {
    const initDataObj = JSON.parse(initData);
    const currentAllocation =
      initDataObj.distribution[initDataObj.distribution.length - 1].endOfRange /
      100;
    if (sliderAllocation === currentAllocation) {
      const initDistribution = initDataObj.distribution;
      setRuleData((prevState: any) => {
        const data = { ...prevState, distribution: initDistribution };
        return data;
      });
    } else {
      if (validAllocation(sliderAllocation)) {
        setRuleData((prevState: any) => {
          const data = {
            ...prevState,
            distribution: getNewRanges(sliderAllocation),
          };
          return data;
        });
      }
    }
  };

  const onChangeVariables = (e: any, index: any) => {
    const { value, type } = e.target;
    let newValue = value;
    if (type === "number") newValue = Number(value);
    const variations = ruleData.variations.map(
      (variation: any, variationKey: number) => {
        if (index.baseIndex !== variationKey) return variation;
        variation.variables.map((variable: any, variableKey: number) => {
          if (index.flagVariIndex === variableKey) {
            variable.value = newValue;
          }
          return variable;
        });
        return variation;
      }
    );

    setRuleData((prevState: any) => {
      const data = { ...prevState, variations: variations };
      return data;
    });
  };

  const onChangeVariation = (e: any, index: number) => {
    const { name, value, type } = e.target;
    let newValue = value;
    if (type === "number") newValue = Number(value);

    const variations = ruleData.variations.map((each: any, key: number) => {
      if (index === key) {
        each[name] = newValue;
      }
      return each;
    });

    setRuleData((prevState: any) => {
      const data = {
        ...prevState,
        variations: variations,
        distribution: getNewRanges(),
      };
      return data;
    });
  };

  const [flagVariables, setFlagVariables] = useState([]);
  const [flagTeam, setFlagTeam] = useState(0);

  useEffect(() => {
    FetchFlag((output: any) => {
      if (output.status === 200) {
        const obj = output.response[0].variables;
        setFlagVariables(obj === null ? [] : obj);
        setFlagFetched(true);
        setFlagArchived(output.response[0].archived === 1);
        setFlagTeam(output.response[0].team_id);
      }
    }, Number(flagId));
  }, [flagId]);

  const [attributes, setAttributes] = useState([]);
  useEffect(() => {
    FetchAttributes((output: any) => {
      if (output.status === 200) {
        const obj = output.response;
        if (obj === null) return;
        setAttributes(obj);
      }
    });
  }, []);

  const addNewVariations = () => {
    const noOfVariations = ruleData.variations.length;
    const trafficArr = evenDistributedAllocataion(noOfVariations + 1);
    const updatedVariations = ruleData.variations.map(
      (each: any, index: number) => {
        return { ...each, traffic: trafficArr[index] };
      }
    );

    const obj = flagVariables.map((vari: any) => {
      return { id: vari.id, value: vari.default_value };
    });

    updatedVariations.push({
      id: noOfVariations,
      name: `Variation #${noOfVariations}`,
      variables: obj,
      traffic: trafficArr[noOfVariations],
    });

    setRuleData((prevState: any) => {
      const data = {
        ...prevState,
        variations: updatedVariations,
        distribution: getNewRanges(sliderAllocation, updatedVariations),
      };

      return data;
    });
    setLastAddedVariation(noOfVariations);
  };

  const getStateName = (state_id: Number) => {
    const state = config?.rule_states?.find((e: any) => {
      return e.state_id === state_id;
    });
    return state ? state["name"] : "";
  };

  const toggleBackdrop = (b: boolean) => {
    setConfig((prevState: any) => {
      return {
        ...prevState,
        showBackdrop: b,
      };
    });
  };

  const preventSchedule = () => {
    return (
      enableUpdate ||
      ruleData.hypothesis.length < 2 ||
      ruleData.variations.length < 2 ||
      ruleData.attributes.length < 1 ||
      !ruleData.attributes.some((e: any) => e.attribute === "locale") ||
      !ruleData.attributes.some((e: any) => e.attribute === "touchpoint") ||
      ruleData.metrics.length < 1 ||
      !ruleData.metrics.some((e: any) => e.is_success)
    );
  };

  const updateState = (state: any) => {
    if (state === 30 || state === 20 || state === 10) {
      if (!isLiveValid(ruleData, state)) {
        return;
      }
    }

    toggleBackdrop(true);
    UpdateRuleState(
      (output: any) => {
        if (output?.status !== 200) {
          enqueueSnackbar(output.message, { variant: "error" });
        } else {
          setRuleData((prevState: any) => {
            return {
              ...prevState,
              state: state,
            };
          });
          const message = `"${fullRuleData.name}" state set to ${getStateName(
            state
          )}`;
          enqueueSnackbar(message, { variant: "success" });
          if (state === 30) {
            setEditDisabled(true);
          }
          updateParent(Date.now().toString());
        }
        toggleBackdrop(false);
      },
      Number(ruleId),
      {
        from_state: ruleData.state,
        state: state,
      }
    );
  };
  const handleStateChange = (event: any) => {
    let message = "";
    let confirmation = false;

    switch (event.target.value) {
      case 30:
        message = config.cms_strings.experiments.state_change_30;
        confirmation = true;
        break;
      case 40:
        message = config.cms_strings.experiments.state_change_40;
        confirmation = true;
        break;
      default:
        message = "";
        break;
    }
    if (confirmation) {
      confirm({
        title: "Change state",
        description: message,
        confirmationButtonProps: { color: "primary", variant: "contained" },
      })
        .then(() => {
          updateState(event.target.value);
        })
        .catch(() => {
          console.log("Cancelled");
        });
    } else {
      updateState(event.target.value);
    }
  };

  const getNewRanges = (
    slider: any = sliderAllocation,
    variations: any = ruleData.variations
  ) => {
    const totalNumberOfBuckets = 10000;
    let cummulativePercentage = 0;

    const ifLiveOrAbove = fullRuleData.state >= 30;
    const lastEndRangeFromOld =
      fullRuleData.distribution.slice(-1)[0].endOfRange;
    const newEndRange = (totalNumberOfBuckets * slider) / 100;
    let newRanges;

    if (ifLiveOrAbove) {
      newRanges = [...fullRuleData.distribution];

      variations.forEach((each: any) => {
        const allocatedRange = newEndRange - lastEndRangeFromOld;
        cummulativePercentage += each.traffic / 100;

        const rangeEnd = Math.round(
          lastEndRangeFromOld + cummulativePercentage * allocatedRange
        );
        const obj = {
          endOfRange: rangeEnd,
          variationId: each.id,
        };
        newRanges.push(obj);
      });
    } else {
      newRanges = variations.map((each: any) => {
        cummulativePercentage += each.traffic / 100;

        const rangeEnd = Math.round(cummulativePercentage * newEndRange);
        const obj = {
          endOfRange: rangeEnd,
          variationId: each.id,
        };
        return obj;
      });
    }
    return newRanges;
  };

  const submitForm = () => {
    console.log("submitForm", ruleData);
    toggleBackdrop(true);
    UpdateRule(
      (output: ApiResponse) => {
        if (output?.status !== 200) {
          enqueueSnackbar(output.message, { variant: "error" });
        } else {
          enqueueSnackbar("Experiment updated", { variant: "success" });
          setEnableUpdate(false);
          initData = JSON.stringify(fullRuleData);
          setRuleData(fullRuleData);
          updateParent(Date.now().toString());
        }
        toggleBackdrop(false);
      },
      Number(ruleId),
      ruleData
    );
  };

  const overrideSave = () => {
    confirm({
      title: "Force update",
      description:
        "Are you sure you want to update this experiment? This will override all sanity checks!",
      confirmationButtonProps: { color: "error", variant: "contained" },
    })
      .then(() => {
        submitForm();
      })
      .catch(() => {
        console.log("Cancelled");
      });
  };

  const removeVariation = (e: any) => {
    setRuleData((prevState: any) => {
      const variations = prevState.variations;
      variations.splice(e.target.dataset.index, 1);

      const trafficArr = evenDistributedAllocataion(variations.length);
      const updatedVariations = variations.map((each: any, index: number) => {
        return { ...each, traffic: trafficArr[index] };
      });

      const data = {
        ...prevState,
        variations: updatedVariations,
        distribution: getNewRanges(sliderAllocation, updatedVariations),
      };

      return data;
    });
  };

  const sortAttributeVaules = (values: any) => {
    return values.sort((a: any, b: any) => {
      return a.localeCompare(b);
    });
  };

  const updateRuleAudience = (audience: any) => {
    setRuleData((prevState: any) => {
      delete audience.description;
      const attributes = prevState.attributes;
      //const index = attributes.findIndex((i: any) => i.attribute === audience.attribute);
      if (audience.index > -1) {
        attributes[audience.index] = audience;
      } else {
        attributes.push(audience);
      }

      const data = {
        ...prevState,
        attributes: attributes,
      };

      return data;
    });
  };

  const addAttributeHandler = (e: any) => {
    const audience = e.target.dataset.audience
      ? JSON.parse(e.target.dataset.audience)
      : JSON.parse(e.currentTarget.dataset.audience);
    const values = audience.values.filter((e: any) => e.length > 0);
    audience.values = sortAttributeVaules(values);
    if (saveGlobal) {
      const storeAudience = JSON.parse(JSON.stringify(audience));
      storeAudience.brand_id = ruleData.brand_id;
      storeAudience.values = audience.values.join("|");
      CreateAudience((output: any) => {
        if (output?.status !== 200) {
          if (
            output.status === 500 &&
            output.response.hasOwnProperty("code") &&
            output.response.code === "ER_DUP_ENTRY"
          ) {
            enqueueSnackbar(cms_strings.experiments.duplicate_audience_error, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(output.message, { variant: "error" });
          }
          setSaveGlobal(false);
        } else {
          enqueueSnackbar(cms_strings.experiments.aucience_saved, {
            variant: "success",
          });
          setSaveGlobal(false);
          updateRuleAudience(audience);
        }
      }, storeAudience);
    } else {
      updateRuleAudience(audience);
    }
  };

  const removeAdudience = (e: any) => {
    setRuleData((prevState: any) => {
      const attributes = prevState.attributes;
      attributes.splice(e.currentTarget.dataset.index, 1);
      const data = {
        ...prevState,
        attributes: attributes,
      };

      return data;
    });
  };

  const toggleStore = (e: any) => {
    setSaveGlobal(e.target.checked);
  };

  const unsavedWarning = () => {
    const confirmation = {
      title: cms_strings.experiments.unsaved_changes_title,
      description: cms_strings.experiments.unsaved_changes_desc,
      confirmationButtonProps: { color: "error", variant: "outlined" },
    };

    return enableUpdate ? confirmation : false;
  };

  const UpdateButtonTooltip = () => {
    const isDisabled =
      config.user.level < 25 ||
      (config.user.region.length > 0 &&
        config.user.region !== ruleData.region_key);
    const btnStickyStyle = { position: "fixed", bottom: "20px", right: "50px" };

    const btn = (
      <Grid
        container
        sx={enableUpdate ? btnStickyStyle : {}}
        justifyContent="flex-end"
        spacing={1}
      >
        <Grid item>
          {config.user.level > 999 ? (
            <Button
              color="warning"
              variant="contained"
              title="Override save"
              onClick={overrideSave}
            >
              Force update
            </Button>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          <Button
            className="pulsate"
            variant="contained"
            onClick={submitForm}
            disabled={!enableUpdate}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    );

    if (isDisabled) {
      return (
        <Tooltip
          title={cms_strings.experiments.edit_not_allowed}
          placement="left"
          arrow
          disableInteractive
        >
          <span>{btn}</span>
        </Tooltip>
      );
    } else {
      return btn;
    }
  };

  const mainContent = () => {
    return (
      <>
        {flagArchived ? (
          <Alert sx={{ mb: 1 }} severity="warning">
            {cms_strings.experiments.feature_archived}
          </Alert>
        ) : (
          <></>
        )}
        <Stack>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Grid item>
              <ButtonBack
                link={"/features/" + flagId}
                text="Experiments"
                confirmation={unsavedWarning()}
              />
            </Grid>
            <Grid
              xs
              item
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Scheduler
                  schedule={ruleData.schedule}
                  ruleId={ruleId}
                  updateParent={updateParent}
                  preventSchedule={preventSchedule}
                />
              </Grid>
              <Grid item>
                <ChangeState
                  rule={ruleData.id}
                  currentState={ruleData.state}
                  handleStateChange={handleStateChange}
                  disabled={
                    config.user.level < 25 ||
                    (config.user.region.length > 0 &&
                      config.user.region !== ruleData.region_key) ||
                    enableUpdate ||
                    flagArchived
                  }
                />
              </Grid>
              <Grid item>
                <MoreMenu item={ruleData} />
              </Grid>
            </Grid>
          </Grid>
        </Stack>

        <GeneralFields
          newRule={ruleData}
          onChangeHandler={onChangeHandler}
          sliderAllocation={sliderAllocation}
          handleSliderChange={handleSliderChange}
          handleSliderCommitted={handleSliderCommitted}
          editDisabled={editDisabled}
          isCreate={false}
          // sliderErrorHandler
        />
        <br />

        <VariationsFields
          newVariations={ruleData.variations}
          onChangeVariation={onChangeVariation}
          flagVariables={flagVariables}
          onChangeVariables={onChangeVariables}
          addNewVariations={addNewVariations}
          removeVariation={removeVariation}
          stateStatus={ruleData.state}
          disableArchive={
            config.user.level < 25 ||
            (config.user.region.length > 0 &&
              config.user.region !== ruleData.region_key)
          }
          editDisabled={editDisabled}
          flagFetched={flagFetched}
          lastAdded={lastAddedVariation}
          isMab={true}
        />
        <br />
        <Grid container>
          <Grid item xs={8}>
            <TrackingPromotionCreativeField
              ruleData={ruleData}
              setRuleData={setRuleData}
              flagVariables={flagVariables}
              editDisabled={editDisabled}
            />
          </Grid>
        </Grid>
        <br />
        <Attributes
          ruleAudiences={ruleData.attributes}
          addAttributeHandler={addAttributeHandler}
          removeAdudience={removeAdudience}
          attributes={attributes}
          editDisabled={editDisabled}
          toggleStore={toggleStore}
          brandId={ruleData.brand_id}
        />

        <br />
        <Metrics
          rule={ruleData}
          setRule={setRuleData}
          team={flagTeam}
          editDisabled={editDisabled}
          metricError={metricError}
        />

        {metricError.length > 0 ? (
          <Alert severity="error">{metricError}</Alert>
        ) : null}
        {ruleData.metrics.length >= 10 ? (
          <Alert severity="warning">Max number of metrics added</Alert>
        ) : null}

        <Divider />
        <Grid container justifyContent="space-between" sx={{ p: 2 }}>
          <Grid item>
            {ruleData.id > 0 ? (
              <Box sx={{ border: "1px solid gray", p: 2 }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">Experiment-ID:</Typography>
                  <Typography variant="subtitle2">{ruleData.id}</Typography>
                </Box>

                {ruleData.variations.map((e: any, index: number) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography variant="body2">{e.name}:</Typography>
                    <Typography variant="subtitle2">{e.id}</Typography>
                  </Box>
                ))}
              </Box>
            ) : null}
          </Grid>
          <Grid item sx={{ position: "relative" }}>
            {UpdateButtonTooltip()}
          </Grid>
        </Grid>
      </>
    );
  };

  if (ruleData.state === undefined || Object.keys(config).length === 0) {
    return <>loading?</>;
  }
  return <>{mainContent()}</>;
};

export default UpdateMab;
