import { enqueueSnackbar } from "notistack";
import { publicClientApplication } from "./Auth";
import { jwtDecode } from "jwt-decode";

export function getAggField(metric: {
  aggregator: string;
  scope: string;
  field: string;
}): string {
  let heading;
  let heading2;
  let heading3;

  if (metric.aggregator === "sum" || metric.aggregator === "count") {
    heading = "Total";
  } else {
    heading = "Unique";
  }

  if (metric.scope === "visitor") {
    heading3 = "Visitors";
  } else if (metric.scope === "event") {
    heading3 = "Conversions";
  }

  if (metric.field === "revenue") {
    heading2 = "Revenue";
  } else if (metric.field === "value") {
    heading2 = "Value";
  } else {
    heading2 = "Conversions";
  }
  return heading + " " + heading2 + " " + heading3;
}

export const evenDistributedAllocataion = (nrOfVariations: number) => {
  let evenPerVariation = Number((100 / nrOfVariations).toFixed(2));

  let arr = [];
  let full = 100;

  for (let i = 1; i <= nrOfVariations; i++) {
    full = full - evenPerVariation;
    if (i === nrOfVariations) {
      if (full !== 0) {
        evenPerVariation = Number(
          (evenPerVariation + Number(full.toFixed(2))).toFixed(2)
        );
      }
    }
    arr.push(Number(evenPerVariation));
  }
  return arr;
};

export const getDefaultTeam = () => {
  const storedId = localStorage.getItem("fabulousTeamId");
  return storedId ? JSON.parse(storedId) : 0;
};

export const setDefaultTeam = (teamId: Number) => {
  localStorage.setItem("fabulousTeamId", JSON.stringify(teamId));
};

export const setDefaultBrand = (brandId: Number) => {
  localStorage.setItem("fabulousBrandId", JSON.stringify(brandId));
};

export const getDefaultBrand = () => {
  const storedId = localStorage.getItem("fabulousBrandId");
  return storedId ? JSON.parse(storedId) : 0;
};

export const setDefaultState = (stateId: Number) => {
  localStorage.setItem("fabulousStateId", JSON.stringify(stateId));
};

export const getDefaultState = () => {
  const storedId = localStorage.getItem("fabulousStateId");
  return storedId ? JSON.parse(storedId) : -1;
};

export const getDefaultRegion = () => {
  const storedId = localStorage.getItem("fabulousUserLatestRegion");
  return storedId ? storedId : "0";
};

export const setDefaultRegion = (region_key: string) => {
  localStorage.setItem("fabulousUserLatestRegion", region_key);
};
export const getDefaultHasLive = () => {
  const check = localStorage.getItem("fabulousUserHasLive");
  return check ? JSON.parse(check) : false;
};

export const setDefaultHasLive = (check: boolean) => {
  localStorage.setItem("fabulousUserHasLive", JSON.stringify(check));
};
export const isLiveValid = (ruleData: any, state: number) => {
  let error = false;

  // Locale is mandatory for all states
  if (!ruleData.attributes.some((e: any) => e.attribute === "locale")) {
    enqueueSnackbar(
      "You need to have at least one audience with the locale attribute",
      { variant: "error" }
    );
    error = true;
  }

  // Variations, touchpoints, hypothesis and success metrics are mandatory for state 30 and 20
  if (state === 30 || state === 20) {
    if (ruleData.type !== "rollout" && ruleData.variations.length < 2) {
      enqueueSnackbar(
        "You need to have at least two variations to publish the experiment",
        { variant: "error" }
      );
      error = true;
    }

    if (!ruleData.attributes.some((e: any) => e.attribute === "touchpoint")) {
      enqueueSnackbar(
        "You need to have at least one audience with the touchpoint attribute",
        { variant: "error" }
      );
      error = true;
    }
    if (
      ruleData.type !== "rollout" &&
      !ruleData.metrics.some((e: any) => e.is_success)
    ) {
      enqueueSnackbar(
        "You need to have at least one success metric to publish the experiment",
        { variant: "error" }
      );
      error = true;
    }
    if (ruleData.hypothesis.includes("state the change on the website/app")) {
      enqueueSnackbar("You must have a hypothesis to publish the experiment", {
        variant: "error",
      });
      error = true;
    }
  }

  return !error;
};

export const validToken = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    const now = Math.round(Date.now() / 1000);
    if (decoded.exp && decoded.exp > now) {
      return true;
    } else {
      sessionStorage.setItem("fabulousTokenExpired", JSON.stringify(true));
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const redirectLogin = () => {
  const account = publicClientApplication.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: ["openid"],
    account: account,
  };
  publicClientApplication.acquireTokenRedirect(accessTokenRequest);
};

export const signOut = () => {
  const account = publicClientApplication.getAllAccounts()[0];
  publicClientApplication.logoutRedirect({
    postLogoutRedirectUri: "/",
    account: account,
  });
};

export const getRedirectUri = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? ":" + port : ""}`;
};
