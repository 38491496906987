import SearchIcon from "@mui/icons-material/Search";

import { OutlinedInput } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

const SearchField = (props: any) => {
  const { value, handleSearchChange, inputRef } = props;
  return (
    <FormControl sx={{ m: 1, width: "98%" }} variant="outlined" size="small">
      <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
      <OutlinedInput
        autoFocus={true}
        autoComplete="off"
        id="outlined-adornment-password"
        value={value}
        onChange={handleSearchChange}
        inputRef={inputRef}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className="calender-icon" />
          </InputAdornment>
        }
        label="Password"
      />
    </FormControl>
  );
};

export default SearchField;
