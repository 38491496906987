import React from "react";
import { InputLabel, FormControl, Select, MenuItem } from "@mui/material";

import { useConfig } from "./../context";

const ChangeState: React.FC<any> = ({
  rule,
  currentState,
  handleStateChange,
  disabled,
}) => {
  const { config } = useConfig();

  const checkDisabled = (state_id: number) => {
    return (currentState === 30 && state_id !== 40) ||
      (currentState >= 30 && state_id < currentState)
      ? true
      : false;
  };
  if (Object.keys(config).length === 0) return <div>Loading states</div>; // Waiting for config
  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-simple-select-label">State</InputLabel>
        <Select
          disabled={disabled}
          value={currentState}
          onChange={handleStateChange}
          label="State"
          name={rule.toString()}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          {config.rule_states.map(
            (item: { name: string; state_id: number }) => {
              return (
                <MenuItem
                  key={item.name}
                  value={item.state_id}
                  disabled={checkDisabled(item.state_id)}
                >
                  {item.name}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default ChangeState;
