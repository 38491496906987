import { IVariation } from "../types/Types";
import React, { useState, MouseEvent, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Alert,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ExpandGraphButton } from "./ExpandGraphButton";
import { Row } from "./Row";
import { Chart } from "./Chart";
import { Scorecard } from "./Scorecard";
import HelpToolTip from "../components/common/HelpToolTip";
import { useConfig } from "../context";
import { useTheme } from "@mui/material/styles";
import { interaction } from "../Tracking";

const getConversionLabel = (type: string, config: any) => {
  switch (type) {
    case "means":
      return (
        <>
          <b>Value</b>
          <HelpToolTip text={config.cms_strings.results.value_tooltip} />
        </>
      );
    case "count":
      return (
        <>
          <b>Conversions per visitor</b>
          <HelpToolTip text={config.cms_strings.results.conversion_tooltip} />
        </>
      );
    case "items":
      return (
        <>
          <b>Items per visitor</b>
          <HelpToolTip text={config.cms_strings.results.items_tooltip} />
        </>
      );
    default:
      return (
        <>
          <b>Conversion Rate</b>
          <HelpToolTip
            text={config.cms_strings.results.conversio_rate_tooltip}
          />
        </>
      );
  }
};

export const MetricItem = (props: any) => {
  const { config } = useConfig();
  const [showChart, setShowChart] = React.useState(false);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const innerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    if (!height) {
      setHeight(innerRef.current?.offsetHeight);
    }
  }, [height]);

  const showGraphHandler = (event: MouseEvent) => {
    setShowChart((prev) => (prev === false ? true : false));
    interaction("toggle_graph");
  };
  /*
  const getConversionLabel = () => {
    switch (props.metric.type) {
      case "prop":
        return "Conversion Rate";
      case "means":
        return "Value";
      case "count":
        return "Number of Conversions";
      default:
        return "Conversion Rate";
    }
  };
  */
  const getVariationVisitorCount = (variationId: number) => {
    const varData = props.bucketedVisitors.variations.find((i: any) => {
      return parseInt(i.variationId) === variationId;
    });
    return varData ? Intl.NumberFormat().format(varData.number_bucketed) : 0;
  };
  const cssForSuccess =
    props.metric.category === "success"
      ? "0px 0px 2px 2px rgb(149 199 241)"
      : "none";
  return (
    <Scorecard
      mainHeading={props.metric.name}
      smallHeading={"Metric type: " + props.metric.category.toUpperCase()}
      statusColor="inherit"
      myRef={innerRef}
      key={props.metric.name + props.index}
      sx={{ position: "relative" }}
      className="graphTransition"
      style={showChart ? { height: "650px" } : { height: height }}
      customSx={{ boxShadow: cssForSuccess }}
    >
      <Box>
        <Typography fontSize={10}>Event name: {props.metric.event}</Typography>
        <Typography fontSize={10}>
          Desired impact: {props.winning_direction || "positive"}
        </Typography>
      </Box>
      {props.metric.variations.length > 0 ? (
        <TableContainer sx={{ marginTop: "-40px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right" vertical-align="bottom">
                  <Grid container alignItems="center" justifyContent="right">
                    <b>Improvement</b>
                    <HelpToolTip
                      text={config.cms_strings.results.improvement_tooltip}
                    ></HelpToolTip>
                  </Grid>
                </TableCell>
                <TableCell align="right" vertical-align="bottom">
                  <Grid container alignItems="center" justifyContent="right">
                    {config.cms_strings
                      ? getConversionLabel(props.metric.type, config)
                      : ""}
                  </Grid>
                </TableCell>
                <TableCell align="right" vertical-align="bottom">
                  <Grid container alignItems="center" justifyContent="right">
                    {" "}
                    <b>Converting visitors </b>
                    <HelpToolTip
                      text={
                        config.cms_strings.results.converting_visitors_tooltip
                      }
                    ></HelpToolTip>
                  </Grid>
                </TableCell>
                <TableCell
                  align="right"
                  vertical-align="bottom"
                  sx={{
                    color:
                      props.metric.category === "monitoring"
                        ? theme.palette.text.disabled
                        : "inherit",
                  }}
                >
                  <Grid container alignItems="center" justifyContent="right">
                    <b>Confidence Interval</b>
                    <HelpToolTip
                      text={config.cms_strings.results.confidence_tooltip}
                    ></HelpToolTip>
                  </Grid>
                </TableCell>
                <TableCell
                  align="right"
                  vertical-align="bottom"
                  sx={{
                    color:
                      props.metric.category === "monitoring"
                        ? theme.palette.text.disabled
                        : "inherit",
                  }}
                >
                  <Grid container alignItems="center" justifyContent="right">
                    <b>Statistical Significance</b>
                    <HelpToolTip
                      text={config.cms_strings.results.statsig_tooltip}
                    ></HelpToolTip>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(props.metric.variations).map(
                (value: unknown, index) => {
                  const variation = value as IVariation;

                  return (
                    <Row
                      key={index}
                      type={props.metric.type}
                      event={props.metric.event}
                      {...variation}
                      winning_direction={props.winning_direction || "positive"}
                      getVariationName={props.getVariationName}
                      getVariationColor={props.getVariationColor}
                      getVariationVisitorCount={getVariationVisitorCount}
                      category={props.metric.category}
                      isSegmented={props.isSegmented}
                    ></Row>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Paper sx={{ mt: 2 }}>
          <Alert severity="info">No data for this metric.</Alert>
        </Paper>
      )}
      <div
        className="graphTransition"
        style={
          showChart
            ? { height: "400px", overflow: "hidden" }
            : { height: "0px" }
        }
      >
        {showChart && (
          <Chart
            metricIndex={props.index}
            category={props.metric.category}
            experimentId={props.experimentId}
            name={props.metric.name}
            type={props.metric.type}
            event={props.metric.event}
            getVariationName={props.getVariationName}
            getVariationColor={props.getVariationColor}
            mockEnabled={props.mockEnabled}
          />
        )}
      </div>
      <ExpandGraphButton
        style={
          showChart
            ? {
                transform: "rotate(180deg)",
              }
            : {
                transform: "rotate(0deg)",
              }
        }
        handleClick={showGraphHandler}
        disableGraph={props.disableGraph}
      />
    </Scorecard>
  );
};
