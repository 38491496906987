import React, { useEffect, useState, useCallback } from "react";
import { renderToString } from "react-dom/server";
import { useNavigate } from "react-router-dom";

import ReactApexChart from "react-apexcharts";
import { FetchTimeline } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import TimeBoardTooltip from "./TimeBoardTooltip";
import { useTheme } from "@mui/material/styles";
import { Grid, Card, Typography } from "@mui/material";
import TimeBoardFilters from "./TimeBoardFilters";
import { Timeserie, ApiTimeserie } from "./Types";

const TimeBoard: React.FC<any> = (props: any) => {
  const theme = useTheme();

  const [data, setData] = useState<Timeserie[]>([]);
  const [apiData, setApiData] = useState<ApiTimeserie[]>([]);
  const [locale, setLocale] = useState("0");
  const [brand, setBrand] = useState(0);
  const [page, setPage] = useState(0);
  const [touchpoint, setTouchpoint] = useState("0");
  const navigate = useNavigate();

  const clickExperiment = (event: any, chartContext: any, config: any) => {
    const experiment = apiData[config.seriesIndex];
    if (!experiment) return;
    navigate("/experiment/" + experiment.id);
  };
  const processData = useCallback((apiTimeseries: ApiTimeserie[]) => {
    const timeserieMap: { [key: string]: Timeserie } = {};

    apiTimeseries.forEach((apiTimeserie) => {
      if (!timeserieMap[apiTimeserie.name]) {
        timeserieMap[apiTimeserie.name] = {
          name: apiTimeserie.name,
          data: [],
        };
      }

      const planned_start = apiTimeserie.planned_start_date
        ? moment(apiTimeserie.planned_start_date)
        : moment();
      const planned_end = apiTimeserie.planned_end_date
        ? moment(apiTimeserie.planned_end_date)
        : apiTimeserie.start_time
        ? moment(apiTimeserie?.start_time).add(15, "day")
        : moment();
      timeserieMap[apiTimeserie.name].data.push({
        x: apiTimeserie.decision_page,
        y: [planned_start.valueOf(), planned_end.valueOf()],
      });
    });
    setData(Object.values(timeserieMap));
  }, []);

  useEffect(() => {
    const params = {
      brand: brand,
      locale: locale,
      page: page,
      touchpoint: touchpoint,
    };
    FetchTimeline((output: ApiResponse) => {
      if (output?.status !== 200) {
        enqueueSnackbar(output.message, { variant: "error" });
      } else {
        processData(output.response);
        setApiData(output.response);
      }
    }, params);
  }, [processData, brand, locale, page, touchpoint]);

  const maxDate = moment().add(1, "month").valueOf();
  const minDate = moment().valueOf();
  const a: any = {
    chart: {
      type: "rangeBar",
      height: "400px",
      toolbar: {
        autoSelected: "pan",
        tools: {
          download: false,
        },
      },
      events: {
        click: clickExperiment,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",
      },
    },
    xaxis: {
      type: "datetime",
      min: minDate,
      max: maxDate,
    },
    stroke: {
      width: 1,
    },
    fill: {
      type: "solid",
      opacity: 0.6,
    },
    legend: {
      show: false,
    },
    annotations: {},
    theme: {
      mode: theme.palette.mode,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      formatter: function (val: any, opt: any) {
        return opt.w.globals.initialSeries[opt.seriesIndex].name;
      },
      offsetX: 0,
    },
    tooltip: {
      theme: "dark",
      custom: function ({ seriesIndex }: { seriesIndex: number }) {
        return renderToString(
          <TimeBoardTooltip
            item={apiData[seriesIndex]}
            mode={theme.palette.mode}
          />
        );
      },
    },
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TimeBoardFilters
          setLocale={setLocale}
          locale={locale}
          setBrand={setBrand}
          brand={brand}
          setPage={setPage}
          page={page}
          setTouchpoint={setTouchpoint}
          touchpoint={touchpoint}
        />
      </Grid>
      <Grid item xs={12} height="80vh">
        {data.length === 0 ? (
          <Card sx={{ padding: 3 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs="auto">
                <Typography variant="h5" color="text.secondary">
                  There is no experiments planned for the selected filters.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <ReactApexChart
            options={a}
            series={data}
            type="rangeBar"
            height="100%"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TimeBoard;
