import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Stack,
  Paper,
  Pagination,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FetchFlags } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";
import SearchField from "../components/common/Searchfield";
import ErrorMessage from "../components/common/ErrorMessage";
import NewFlag from "./NewFlag";
import { useConfig } from "./../context";
import {
  getDefaultTeam,
  setDefaultTeam,
  getDefaultBrand,
  setDefaultBrand,
  setDefaultHasLive,
  getDefaultHasLive,
} from "../helpers/Helpers";
import Loader from "../components/common/Loader";
import { useDebounce } from "../helpers/useDebounce";
import { useTitle } from "../helpers/useTitle";
import { interaction } from "../Tracking";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 500,
      width: 250,
      marginLeft: 50,
    },
  },
};

const FlagsToolbar: React.FC<any> = (props: any) => {
  const handleInputChange = (event: any) => {
    props.setPage(1);
    props.setSearchQuery(event.target.value);
  };

  const handleTeamChange = (event: any) => {
    props.setPage(1);
    props.setTeam(Number(event.target.value));
    setDefaultTeam(Number(event.target.value));
    interaction("filter_team");
  };

  const handleHasLiveRulesChange = (event: any) => {
    props.setPage(1);
    props.setHasLiveRules(event.target.checked);
    setDefaultHasLive(event.target.checked);
    interaction("filter_live");
  };

  const handleBrandChange = (event: any) => {
    props.setPage(1);
    props.setBrand(Number(event.target.value));
    setDefaultBrand(Number(event.target.value));
    interaction("filter_brand");
  };

  const { config } = useConfig();

  useTitle("Features");

  return (
    <Box>
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid md={3} xs={6}>
          <SearchField
            value={props.searchQuery}
            handleSearchChange={handleInputChange}
          />
        </Grid>
        <Grid md={2} xs={6}>
          <FormControl
            sx={{ m: 1, width: "98%" }}
            size="small"
            className={props.brand > 0 ? "active-filter" : ""}
          >
            <InputLabel id="brand-select-label">Brand</InputLabel>
            <Select
              value={config?.brands?.length > 0 ? props.brand : ""}
              onChange={handleBrandChange}
              labelId="brand-select-label"
              id="brand-select"
              label="Brand"
              MenuProps={MenuProps}
            >
              <MenuItem value="0">All</MenuItem>
              {config?.brands?.map((item: { name: string; id: number }) => {
                return (
                  <MenuItem key={item.name} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid md={2} xs={3}>
          <FormControl
            sx={{ m: 1, width: "98%" }}
            size="small"
            className={props.team > 0 ? "active-filter" : ""}
          >
            <InputLabel id="demo-simple-select-label">Team</InputLabel>
            <Select
              value={config?.teams?.length > 0 ? props.team : ""}
              onChange={handleTeamChange}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Team"
              MenuProps={MenuProps}
            >
              <MenuItem value="0">All</MenuItem>
              {config?.teams?.map((item: { name: string; id: number }) => {
                return (
                  <MenuItem key={item.name} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid md={2} xs={3}>
          <FormControl sx={{ mt: "-10px" }}>
            <FormControlLabel
              value="top"
              control={
                <Switch
                  size="small"
                  checked={props.hasLiveRules}
                  onChange={handleHasLiveRulesChange}
                  color="success"
                  sx={{ mt: "-10px" }}
                />
              }
              label={<Typography variant="overline">Live</Typography>}
              labelPlacement="top"
            />
          </FormControl>
        </Grid>
        <Grid xs="auto" sx={{ marginLeft: "auto" }}>
          {config?.user?.level > 30 ? <NewFlag /> : ""}
        </Grid>
      </Grid>
    </Box>
  );
};

const Flags: React.FC<{}> = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState<any>();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  //const [originalData, setOriginalData] = useState<ApiResponse>();
  const [numberOfPages, setnumberOfPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [team, setTeam] = useState(0);
  const [brand, setBrand] = useState(0);
  const [hasLiveRules, setHasLiveRules] = useState(false);
  const [region] = useState("");
  const { config } = useConfig();

  const debouncedSearch = useDebounce<string>(searchQuery, 200);
  useEffect(() => {
    setIsLoaded(false);
    const team = getDefaultTeam();
    setTeam(team);
    const brand = getDefaultBrand();
    setBrand(brand);
    const hasLive = getDefaultHasLive();
    setHasLiveRules(hasLive);
    FetchFlags(
      (output: ApiResponse) => {
        setIsLoaded(true);
        setData(output);
        setnumberOfPages(Math.ceil(output.response.count / pageSize));
      },
      page,
      pageSize,
      debouncedSearch,
      team,
      hasLiveRules,
      brand
    );
  }, [page, pageSize, debouncedSearch, team, region, hasLiveRules, brand]);

  const getTeamName = (item: any) => {
    const team = config?.teams?.find((team: any) => team.id === item.team_id);
    const result = team ? team["name"] : "unknown";
    return result;
  };

  const getBrandName = (item: any) => {
    const brand = config?.brands?.find(
      (brand: any) => brand.id === item.brand_id
    );
    const result = brand ? brand["name"] : "unknown";
    return result;
  };

  const Flagslist: React.FC<{}> = () => {
    const navigate = useNavigate();

    const viewFlag = (flagId: Number) => {
      navigate("/features/" + flagId);
    };
    if (data?.status !== 200) {
      return <ErrorMessage message={data?.message} />;
    } else {
      if (Object.keys(config).length === 0) return <></>; // Waiting for config
      return (
        <Box sx={{ pt: 2 }}>
          <Stack spacing={2}>
            {data?.response.results.map((item: any, key: any) => (
              <Paper
                onClick={() => viewFlag(item.id)}
                key={key}
                data-flagid={item.id}
                sx={{
                  pl: 2,
                  pr: 2,
                  pt: 1,
                  cursor: "pointer",
                  height: "80px",
                }}
                className={item.rules_in_prod ? "rule-is-live" : ""}
              >
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid xs={8}>
                    <Typography variant="button" display="block">
                      {item.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      noWrap={true}
                    >
                      {item.description.slice(0, 100)}
                    </Typography>
                    <Typography
                      sx={{ fontStyle: "italic" }}
                      variant="caption"
                      display="block"
                      gutterBottom
                    >
                      Feature key: {item.flag_key}
                    </Typography>
                  </Grid>
                  <Grid xs maxHeight="75px" overflow="hidden">
                    <Typography variant="caption" display="block">
                      <strong>Brand:</strong> {getBrandName(item)}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      lineHeight={1.1}
                    >
                      <strong>Team:</strong> {getTeamName(item)}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={2}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {item.rules_in_prod > 0 ? (
                      <Typography variant="caption" display="block">
                        {item.rules_in_prod > 1
                          ? item.rules_in_prod + " Live experiments"
                          : item.rules_in_prod + " Live experiment"}{" "}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Stack>
        </Box>
      );
    }
  };

  return (
    <>
      <FlagsToolbar
        setSearchQuery={setSearchQuery}
        setPage={setPage}
        team={team}
        brand={brand}
        setTeam={setTeam}
        setBrand={setBrand}
        setHasLiveRules={setHasLiveRules}
        hasLiveRules={hasLiveRules}
      />

      {isLoaded ? (
        <Flagslist />
      ) : (
        <Box sx={{ pt: 2 }}>
          {" "}
          <Loader />
        </Box>
      )}
      <Pagination
        sx={{
          marginTop: "35px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        count={numberOfPages}
        page={page}
        onChange={(event, value) => setPage(value)}
      />
    </>
  );
};

export default Flags;
